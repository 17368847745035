.project-update-details-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  height: 100%;
  position: relative;
}
.update-artifacts-wrapper {
  flex-basis: 66.2%;
  flex-direction: column;
  gap: 2vh;
  max-width: 66.2%;
  display: flex;
}
.update-list-wrapper {
  flex-basis: 34%;
  background: white;
  padding: 0 2em 2em 1em;
  right: 0;
  overflow: auto;
  position: absolute;
  max-height: 100%;
  min-height: 100%;
  width: 32.5%;
}

.form-update-wrapper {
  padding: 0 2em;
  background: white;
}

.update-form-wrapper {
  flex-basis: 66.2%;
  background: white;
  padding: 0 2em;
  max-width: 66.5%;
  min-height: 55.5vh;
}
