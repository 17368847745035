@import "../../../styles/colorbox.scss";
.back-btn {
  border: 1px solid $light-gray;
  border-radius: 12px;
  margin-left: 40px;
  cursor: pointer;
  padding: 4px 14px;

  &:hover {
    border: 1px solid $red-pill-button;
  }
}

@media (max-width: 450px) {
  .main-content-align {
    .processHeading2 {
      &.mb-56,
      &.mb-80 {
        margin-bottom: 40px;
      }
    }
  }
}
