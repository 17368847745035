@import "../../styles/colorbox.scss";

$tooltip-margin: 0.5rem;
$tooltip-arrow-size: 0.75rem;

/* Wrapping */
.tooltip-wrapper {
  display: inline-block;
  position: relative;

  /* Absolute positioning */
  .tooltip-tip {
    position: absolute;
    width: max-content;
    max-width: 23rem;
    max-height: 28rem;
    border-radius: 22px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1.5rem;
    color: $tooltip-text-color;
    background: $tooltip-background-color;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    box-shadow: 0px 17px 80px $tooltip-shadow-color;
    z-index: 1;

    .content {
      max-height: 22rem;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    /* CSS border triangles */
    &::before {
      content: " ";
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: $tooltip-arrow-size;
      margin-left: calc($tooltip-arrow-size * -1);
    }

    /* Absolute positioning */
    &.top {
      top: calc($tooltip-margin * -1);

      /* CSS border triangles */
      &::before {
        top: 100%;
        border-top-color: $tooltip-background-color;
      }
    }

    /* Absolute positioning */
    &.right {
      left: calc(100% + $tooltip-margin);
      top: 50%;
      transform: translateX(0) translateY(-50%);

      /* CSS border triangles */
      &::before {
        left: calc($tooltip-arrow-size * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: $tooltip-background-color;
      }
    }

    /* Absolute positioning */
    &.left {
      left: auto;
      right: calc(100% + $tooltip-margin);
      top: 50%;
      transform: translateX(0) translateY(-50%);

      /* CSS border triangles */
      &::before {
        left: auto;
        right: calc($tooltip-arrow-size * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: $tooltip-background-color;
      }
    }

    /* Absolute positioning */
    &.bottom {
      bottom: calc($tooltip-margin * -1);

      /* CSS border triangles */
      &::before {
        bottom: 100%;
        border-bottom-color: $tooltip-background-color;
      }
    }
  }

  .close-icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;

    &.tooltip-heading {
      justify-content: space-between;
    }

    img {
      position: relative;
      top: -6px;
      width: 1.25rem !important;
      height: 1.25rem !important;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .tooltip-heading {
    font-size: 1rem;
    font-weight: 700;
    color: $tooltip-text-color;
  }

  .quiz-heading {
    color: $tooltip-quiz-heading-color;
    text-decoration: underline;
    font-size: 0.875rem;

    &:hover {
      cursor: pointer;
    }
  }

  .quiz-section {
    &:nth-of-type(2) {
      margin-top: 3rem;
    }
  }
}

.tooltip-parent {
  position: unset;
}

@media (max-width: 450px) {
  .tooltip-parent {
    position: relative;
  }

  .tooltip-wrapper {
    position: static;
    .tooltip-tip {
      width: 85vw;
      max-width: 20rem;

      &.translate-Y-neg-110\% {
        transform: translateY(-110%) !important;
      }

      &::before {
        margin: 0;
        border: 0;
      }

      &.left,
      &.right {
        top: auto;
        left: 50%;
        margin-left: -10rem;
        transform: translateY(0.5rem);
      }
    }
  }
}

@media (max-width: 376px) {
  .tooltip-parent {
    position: relative;
  }

  .tooltip-wrapper {
    position: static;
    .tooltip-tip {
      width: 85vw;
      max-width: 20rem;

      &::before {
        margin: 0;
        border: 0;
      }

      &.left,
      &.right {
        top: auto;
        left: 50%;
        margin-left: -42.5vw;
        transform: translateY(0);
      }
    }
  }
}
