@import "./src/styles/colorbox";

.table-action-buttons {
  display: flex;
  a {
    color: unset;
    font-weight: 600;
  }
  .table-more-button {
    margin-left: 5px;
    cursor: pointer;
  }
}

.text-button {
  display: inline-block;
  cursor: pointer;
  height: fit-content;
  background-color: $light-gray4;
  padding: 6px 10px;
  border: 1px solid $light-gray6;
  margin: 4px 2px;
  border-radius: 8px;
}

.delete-btn {
  @extend.text-button;
  color: white;
  background-color: $red-pill-button;
}

.project-date-picker {
  width: 100%;
}

.create-project-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
