$blue-color: #1b3442;
$bottom-navbar-color: rgba(255, 255, 255, 0.62);
$oceanBlue-color: #61cab0;
$black-color: #000;
$shadow-black: rgb(161, 173, 185);
$tooltip-text-color: #5e656a;
$tooltip-background-color: #ffffff;
$tooltip-shadow-color: #c8d8e4;
$tooltip-quiz-heading-color: #9d9d9d;
$grey-color: #eaeaea;
$navy-blue: #0d1035;
$blue: #28a0ff;
$ant-design-blue: #1890ff;
$red-pill-button: #ed7272;
$white: #ffffff;
$indigo-dye: #00446f;
$bg-blue: #00244a;
$gray: #979797;
$light-gray: #c4c4c4;
$grey-color: #aeafaf;
$tintash-blue: #1791e5;
$dark-grey: #4b4b4b;
$dark-grey2: #808080;
$red: #f16262;
$red-basic: #ff0000;
$yellow: #ffec3e;
$green: #6bde92;
$impure-black: #323232;
$sea-green: #24cdaf;
$light-red: #fef6f6;
$grey-white: #f8f9f9;
$dark-blue: #0373af;
$light-grey: #e6e6e6;
$light-grey-white: #f5faff;
$dark-grey3: #d7d7d7;
$gray-outline: #b5bcc1;
$gray-light: #aab4bf;
$light-gray1: #979797;
$pop-orange: #ff6b00;
$pop-orange-light: #fff9f5;
$sea-green: #61cab0;
$sea-green-light: #f4fbf9;
$pop-red: #f22727;
$pop-red-light: #fff5f5;
$dark-grey4: #727f8f;
$light-gray2: #f4f5f7;
$light-white: #f3f5f6;
$french-blue: #0373af;
$light-gray3: #e6e6e6;
$light-gray4: #f1f1f1;
$light-gray5: #c8d0d0;
$dark-grey5: #6a7c7c;
$disable-gray: #dedfe0;
$light-gray6: #d1d1d1;
$light-gray7: #aab4bf;
$light-gray8: #aab4bf;
$del-red: #d44a4a;
$light-blue: #dfebf4;
$light-blue2: #7592a9;
$dark-grey6: #56637f;
$light-green: #96d8c145;
$light-green2: #84c4b3;
$white-translucent: rgba(255, 255, 255, 0.62);

.review-color-box {
  justify-content: center;
  color: $light-gray7;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  width: max-content;
  height: 80px;
  font-weight: 600;
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 1rem;

  &:hover {
    color: $blue-color;
    border-bottom: 6px solid $gray-light;
  }
}

.reviewColorBox1,
.reviewColorBox2,
.reviewColorBox3 {
  &.active {
    color: $impure-black;
    border-bottom: 6px solid $gray-light;
  }
  &::before {
    margin-right: 1.25rem;
    width: 1.5rem;
    height: 2rem;
    border-radius: 1.75rem;
    transform: rotate(90deg);
    position: relative;
    left: 5px;
    content: "";
  }
}

.reviewColorBox1 {
  @extend.review-color-box;
  padding-left: 0;
  &::before {
    background: $light-gray8;
  }
}
.reviewColorBox2 {
  @extend.review-color-box;
  &::before {
    background: $light-green2;
  }
}
.reviewColorBox3 {
  @extend.review-color-box;
  &::before {
    background: $light-blue2;
  }
}

@media (max-width: 450px) {
  .review-color-box {
    &:hover {
      color: $light-gray7;
      border-bottom: 1px solid $gray-light;
    }
  }

  .reviewColorBox1,
  .reviewColorBox2,
  .reviewColorBox3 {
    border-bottom: 1px solid $gray-light;

    &.active {
      color: $impure-black;
      border-bottom: 6px solid $gray-light;
    }
  }
}
