@import "../../../styles/colorbox.scss";

.development-story {
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 171%;
  letter-spacing: 1%;
  line-height: 20px;
  color: #323232;
  margin-top: 24px;
  margin-left: 25%;
  margin-right: 25%;
  text-align: center;
}

.waterfall-chart {
  margin-bottom: 1.5rem;
}

.develop-heading {
  display: inline-block;
}

.timelapse-develop {
  width: 5rem;
  height: 5rem;
  margin-left: -1rem;
}

.workplan-navbar-item {
  font-size: 20px;
  color: $dark-grey2;
  margin: 0.5rem 1rem;
}

.workplan-navbar-item-wrapper {
  padding: 0px;
  list-style: none;
}
.workplan-active-navbar-item {
  border-bottom: 2px solid $tintash-blue;
  font-weight: 700;
  color: $black-color;
}
.web-products h5 {
  margin-left: 2.5rem;
}
.web-products li {
  font-family: "Inter";
  font-size: 14px;
  line-height: 20px;
  vertical-align: top;
  letter-spacing: 1%;
  margin-left: 2rem;
}

.mobile-products h5 {
  margin-left: 2.5rem;
}
.mobile-products li {
  font-family: "Inter";
  font-size: 14px;
  line-height: 20px;
  vertical-align: top;
  letter-spacing: 1%;
  margin-left: 2rem;
}

.green-light {
  width: 18px;
  height: 18px;
  background: $green;
  margin-right: 5px;
  border-radius: 20px;
}
.yellow-light {
  width: 18px;
  height: 18px;
  background: $yellow;
  margin-right: 5px;
  border-radius: 20px;
}
.red-light {
  width: 18px;
  height: 18px;
  background: $red;
  border-radius: 20px;
}

.desktop-hidden {
  display: none;
}

@media (max-width: 450px) {
  .mobile-hidden,
  .whitespace {
    display: none;
  }

  .desktop-hidden {
    display: block;
  }

  .waterfall-chart {
    margin: 0;
  }

  #intro-row {
    margin: 0;
    padding: 0;

    .col-5 {
      width: 90%;
      padding: 0;
      margin: 0 auto;

      .mobile-flex {
        display: flex;
        align-items: center;
        margin-bottom: 2.25rem;
        .processHeading2 {
          margin: 0;
        }

        img {
          margin-right: 1rem;
        }
      }

      .lead-content {
        width: 100%;
      }
    }
  }

  .principlesContainer {
    margin: 0;
    padding: 0;

    .row {
      .col-6 {
        width: 90%;
        padding: 0;
        margin: 0 auto;

        .col-10 {
          width: 100%;
          padding: 0;
          margin: 0;

          h1.processHeading4 {
            margin: 0;
          }

          img.desktop-hidden {
            height: 2.25rem;
            width: 2.25rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }

  header {
    .header-list-wrapper {
      margin: 0;
      padding: 0;
      width: 100%;
      overflow-x: scroll;
      margin-bottom: 2rem;

      // Scrollbar dimensions for Firefox
      scrollbar-width: none;

      // Scrollbar dimensions for other browsers
      &::-webkit-scrollbar {
        display: none;
      }

      .header-main-items-wrapper {
        margin: 0;

        .workplan-navbar-item-wrapper {
          flex-wrap: nowrap;

          .workplan-navbar-item {
            width: max-content;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .processRunning {
    li {
      margin-top: 0.25rem;
    }
  }

  .guidelines-section-wrapper {
    .row {
      .statusReport__container {
        display: flex;
        flex-direction: column;

        .development_statusReport__budgetYellow__3XubE {
          order: 1;
        }
        .development_statusReport__budgetPurple__3h-YQ {
          order: 2;
        }

        .development_statusReport__budgetRed__39RfR {
          order: 3;
        }
      }
      .col-4,
      .col-8 {
        width: 100%;
      }
    }
  }

  .col-10 {
    .mobile-flex {
      margin-bottom: 1.25rem;
    }

    ul {
      li {
        margin-top: 4px;
      }
    }
  }

  #linkWhatHappen {
    margin: 0;
    padding: 0;
    margin-top: 2.5rem;
    #what-happens-buttons-container {
      width: 100%;
      margin: 0 auto;
      overflow-y: hidden;
      overflow-x: auto;
      padding: 0;

      .button-state,
      .button-state-active {
        width: max-content;
        max-width: 16.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          min-width: max-content;
        }
      }
    }
  }

  #project-workplan {
    padding: 0 1.5rem;

    .processHeading2 {
      text-align: left;
    }

    .row {
      .col-4 {
        margin: 0;

        &:first-of-type {
          .discoveryChecklist,
          .discoveryChecklistActive {
            padding-left: 0 !important;
          }
        }

        .discoveryChecklist,
        .discoveryChecklistActive {
          margin: 0;
          padding: 1.5rem !important;
        }
      }
      .col-12 {
        display: none;
      }
    }
  }
}
