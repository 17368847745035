@import "../../../styles/colorbox.scss";

desktop-hidden {
  display: none;
}

.release-header-list-wrapper {
  .release-header-main-items-wrapper {
    margin: 0;
    width: max-content;

    .release-workplan-navbar-item-wrapper {
      padding-left: 0;

      div.releaseChecklist,
      div.releaseChecklistActive {
        padding-right: 0;
        padding-left: 0;
        margin-right: 10rem;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .col-12 {
      hr {
        position: relative;
        top: -18px;
        width: 100%;
      }
    }
  }
}

#release-checklist {
  .processHeading2 {
    margin-bottom: 5rem !important;
  }
}
@media (max-width: 450px) {
  .whitespace,
  .mobile-hidden {
    display: none;
  }

  .mobile-flex {
    display: flex;
    align-items: center;

    .processHeading2 {
      margin: 0;
    }

    img.desktop-hidden {
      margin-right: 1rem;
    }

    img.badgeIcon {
      margin-left: 1rem;
    }
  }

  #release-intro-section {
    margin: 0;
    padding: 0;

    .col-5 {
      width: 90%;
      padding: 0;
      margin: 0 auto;
      margin-bottom: 2.25rem;
    }
  }

  .lead-content {
    width: 100%;
  }

  header {
    .release-header-list-wrapper {
      .release-header-main-items-wrapper {
        width: 100%;
        .release-workplan-navbar-item-wrapper {
          flex-direction: row;
          overflow-x: auto;

          // Scrollbar dimensions for Firefox
          scrollbar-width: none;

          // Scrollbar dimensions for other browsers
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }

  #release-checklist {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    header {
      .releaseChecklist {
        border-bottom: 1px solid #aab4bf;

        &:hover {
          font-weight: 600;
          color: $dark-grey2;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .releaseChecklist,
      .releaseChecklistActive {
        padding: 1.5rem;
        width: max-content;
        white-space: nowrap;
        margin-right: 0;

        &:first-of-type {
          padding-left: 0;
        }
      }
    }

    .col-12 {
      display: none;
    }

    h1.processHeading2 {
      margin-bottom: 3rem !important;
    }

    li {
      margin-right: 0;
    }
  }
}

@media (max-width: 330px) {
  #release-checklist {
    * {
      font-size: 102% !important;
    }
  }
}
