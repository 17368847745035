.guidancesContainer {
  margin: 50px 0;
  text-align: left;
}

.guidanceReqs {
  margin-bottom: 10px;
}

.guidance {
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  height: 100%;
  letter-spacing: 0.2px;
}

.guidance__brown {
  background: #fff3e1;
  color: #a57f1f;
}

.guidance__purple {
  background: #f5e1ff;
  color: #5f1684;
}

.guidance__green {
  background: #e1fff1;
  color: #0f8662;
}

.guidance__red {
  background: #ffe4e4;
  color: #cc7373;
}

.guidanceCollapse {
  margin: 0 0 25px 0;
}

.guidance-icon-container {
  display: "flex";
  flex-direction: "column";
}

.guidance-values {
  font-weight: 400;
}

.guidance-key {
  white-space: nowrap;
}

@media (max-width: 450px) {
  .guidancesContainer,
  #parbatContainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
