.guidelines-section-wrapper {
  position: fixed;
  top: 20px;
  overflow: scroll;
  transform: translateX(0%);
  animation: right-to-left 0.5s ease-out forwards;
}

@keyframes right-to-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.file-container {
  display: flex;
  align-items: center;
}

.file-content {
  margin: 0 1em 0 1em;
  text-decoration: underline;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0d1035;
  word-break: break-word;
}
.container {
  box-sizing: border-box;
  right: 0;
  width: 60%;
  position: absolute;
  height: 100%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
