$red: #EA0000;
$pop-red: #ed7272;
$tintash-blue: #1791e5;
$french-blue: #0373af;
$indigo-dye: #00446f;
$impure-black: #323232;
$white: #FFFFFFFF;
$off-white: #efefef;
$sea-green: #61CAB0;
$sea-green-dark: #3fab90;

