.heading {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 0.03em;
  margin: 10px;
  display: flex;
  align-items: center;
}
.buttonContainer {
  margin: 20px 10px 30px 10px;
}
.processParentContainer {
  padding: 40px;
  background: white;
  color: black;
  border-radius: 10px;
}

@media (max-width: 450px) {
  .processIntro {
    .flex {
      display: none;
    }
  }

  .processHeading2 {
    text-align: center;
    margin-bottom: 2.5rem;

    &.mt-56 {
      margin-top: 0;
    }
  }

  p.lead-content {
    font-size: 0.9rem;

    ol,
    ul {
      padding-left: 1rem;
    }
  }

  .processParentContainer {
    padding: 0;
    width: 90%;
    margin: 0 auto;

    .col-8 {
      &.mobile-w-full {
        width: 100%;
        margin: 0;
        padding: 0;
      }
    }
  }
}
