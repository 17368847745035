@import "../../../../styles/colorbox";

.subStageItem {
  margin-top: 1rem;
  margin-left: -2.8rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170.52%;
  text-align: center;
  cursor: pointer;
  padding-bottom: 8px;
  letter-spacing: 0.01em;
  &:hover {
    color: $french-blue;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
}
.substage-item-wrapper,
.substage-item-wrapper-disable {
  cursor: pointer;
  transition: transform 0.2s ease-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.substage-item-wrapper-disable {
  pointer-events: none;
}

.line {
  width: 14vw;
  border-top: 0.5px solid $light-gray3;
}
.active-substage {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 170.52%;
  border-bottom: 3px solid $red-pill-button;
}
.nextProcess {
  display: flex;
  justify-content: flex-end;
}
.nextProcessButton {
  padding: 20px 40px;
  background-color: #4b4b4b;
  border: 2px solid $dark-grey;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 0px;
}

.color-red {
  border-top: 2px solid $red-pill-button;
}

.color-black {
  border-top: 2px solid $light-gray3;
}
