.next-stage-dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em 0 1em;
  gap: 30px;
}
.next-stage-dialog-title {
  font-weight: 500;
  font-size: 1.5em;
  text-align: center;
}
.action-buttons-wrapper {
  display: flex;
  justify-content: center;
  gap: 1em;
}
