@import "../../styles//colorbox.scss";
.sub-form-wrapper {
  padding: 0.5em 0 0 0.5em;
}
.field-label {
  margin: 0 0 0 0.8em;
}
.field-wrapper {
  margin: 0.5em;
}
.comments-field-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0.8em 0;
}
.comment-section {
  width: 100%;
  min-height: 120px;
  margin-top: 16px;
  margin-bottom: 32px;
  padding-bottom: 80px;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
}

.number-section {
  width: 5%;
  max-height: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 30px;
  position: absolute;
  padding-left: 5px;
  overflow: hidden;
  resize: none;
  border-radius: 3px;
}
.sync-up-type-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.field-label {
  margin: 0 0 0 1em;
}
.checkbox-fields-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin: 1em 0;
}
.comment-section-wrapper {
  margin: 1em 0;
  font-weight: bold;
  width: 100%;
  max-width: 48vw;
}

label:hover {
  cursor: pointer;
}

.update-form-root-parent-item {
  font-weight: bold;
}
.comment-section-wrapper .se-controller-table {
  top: -8px !important;
}

.commentsMeeting td {
  max-width: 1rem;
}

.commentsMeeting td:first-child {
  width: 35%;
}
.email-section-wrapper {
  display: flex;
  gap: 1em;
  margin: 0.5em 0 0.5em;

  .email-input {
    max-width: 80%;
    min-width: 60%;
  }
}

.dosTitle {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $impure-black;
}

.guidelines-button {
  max-height: 2.5rem;
  min-height: 2.5rem;
  border-radius: 20px;
  font-family: "Poppins";
  width: 9vw;
  margin-left: 32px;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  background-color: white;
  color: $red-pill-button;
  border: 2px solid $red-pill-button;
  padding: 0.5rem 0.5rem;
  box-shadow: 0 4px 8px 0 rgb(237 114 114 / 16%),
    0 1px 20px 0 rgb(237 114 114 / 16%);
  &:hover {
    background-color: $red-pill-button;
    color: white;
  }
}

.start-button {
  max-height: 2.5rem;
  min-height: 2.5rem;
  border-radius: 20px;
  font-family: "Poppins";
  width: 6vw;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  background-color: $red-pill-button;
  color: white;
  border: 2px solid $red-pill-button;
  padding: 0.3rem 0.5rem;
  &:hover {
    background-color: white;
    color: $red-pill-button;
    border-color: $red-pill-button;
  }
}

.item-heading {
  font-family: "Poppins";
  font-weight: 500;
  margin-top: 0.8vh;
  font-size: 16px;
  line-height: 28.16px;
}

.item-row {
  margin-bottom: 0.6rem;
  justify-content: space-between;
  align-items: center;
}

.child-form-container {
  margin-bottom: 0.8rem;
}
.dynamic-delete-button {
  top: 4px;
  margin: 0 8px;
  color: $dark-grey2;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: $dark-grey;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.input-field-container {
  display: flex;
  flex-direction: column;
}

.input-field-email {
  width: 100%;
  padding-left: 5px;
}

.error-message {
  color: red;
}
.field-container {
  display: flex;
  margin: 1rem;
  margin-top: 0rem;
}

.add-field-icon {
  width: 60%;
  margin: 1rem;
}

.plus-icon-dashed {
  align-self: center;
  margin: 1rem;
  margin-top: 0.2rem;

  &.width-65 {
    width: 65%;
  }
  &.width-55 {
    width: 55%;
  }
}
