@import url("https://fonts.googleapis.com/css?family=Poppins");
@import "../../../../styles/colorbox.scss";

.project-artifacts-wrapper {
  background: white;
  padding: 0 0 2em;
  overflow-x: hidden;
}
.upload-link {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  color: #61cab0;
}
.additionalDocuments {
  cursor: pointer;
  color: $navy-blue;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-top: 0.7rem;
  &:hover {
    color: $french-blue;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
}

.additionalDocuments p {
  margin-top: 0.7rem;
}
.file-name-wrapper {
  font-weight: 500;
  color: $navy-blue;
  font-size: 14px;
  font-family: "Poppins";
  margin: 0 0 0 1em;
  cursor: pointer;
  text-decoration: underline;
  max-width: 57vw;
  overflow: hidden;
  display: table-cell;
  word-wrap: break-word;
  &:hover {
    color: $french-blue;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
}
.files {
  border-bottom: 0.5px solid $light-gray1;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 0.5px solid $dark-grey3;
  padding: 0.85rem 0rem;
}
.help-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-left: 56px;
  color: $light-gray1;
}

.file-content-project-artifacts {
  display: flex;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  align-content: center;
  line-height: 17px;
  color: $light-gray1;
  word-break: break-word;
}

.pending-for-approval {
  color: $pop-orange;
  font-family: "Poppins";
  white-space: nowrap;
  font-size: 14px;
  padding: 1rem;
  border-radius: 5px 10px 5px 10px;
  background-color: $pop-orange-light;
  border-radius: 4px;
}

.accepted {
  color: $sea-green;
  font-family: "Poppins";
  font-size: 14px;
  padding: 1rem;
  border-radius: 5px 10px 5px 10px;
  background-color: $sea-green-light;
  border-radius: 4px;
}

.rejected {
  color: $pop-red;
  font-family: "Poppins";
  font-size: 14px;
  padding: 1rem;
  border-radius: 5px 10px 5px 10px;
  background-color: $pop-red-light;
  border-radius: 4px;
}

.upload {
  color: $dark-grey4;
  font-family: "Poppins";
  font-size: 14px;
  padding: 1rem;
  border-radius: 5px 10px 5px 10px;
  background-color: $light-gray2;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button {
  min-width: 3rem;
  cursor: pointer;
}

.file-row {
  width: 100%;
}

.artifacts-title {
  line-height: 24px;
  font-size: 22px;
  font-family: "Inter";
  font-weight: 600;
  padding: 1.5rem 0rem 0rem 2rem;
}

.add-url-submit,
.add-file-submit {
  display: flex;
  justify-content: center;
}

.paper-clip-icon {
  margin-left: 10%;
}

.updates-top-margin {
  height: 2vh;
}

.project-artifacts {
  max-height: 49vh;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: $light-gray4;
}
::-webkit-scrollbar-thumb {
  background: $light-gray5;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
  background: $dark-grey5;
}

.disable-delete-button {
  pointer-events: none;
}
