.m-4 {
  margin: 4px;
}

.m-8 {
  margin: 8px;
}

.m-16 {
  margin: 16px;
}

.m-24 {
  margin: 24px;
}

.mh-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.mh-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mh-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.mh-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.mv-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mv-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-24 {
  margin-right: 24px;
}

.mr-144 {
  margin-right: 144px;
}

.mr-288 {
  margin-right: 288px;
}
.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}
.ml-34 {
  margin-left: 34px;
}

.ml-32 {
  margin-left: 32px;
}

.ml-44 {
  margin-left: 44px;
}

.ml-48 {
  margin-left: 48px;
}
.ml-56 {
  margin-left: 56px;
}

.ml-240 {
  margin-left: 240px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-26 {
  margin-top: 26px;
}

.mt-32 {
  margin-top: 32px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-48 {
  margin-top: 48px;
}
.mt-56 {
  margin-top: 56px;
}
.mt-64 {
  margin-top: 64px;
}
.mt-70 {
  margin-top: 70px;
}

.mt-88 {
  margin-top: 88px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-none {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-18 {
  margin-bottom: 18px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-56 {
  margin-bottom: 56px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-164 {
  margin-bottom: 164px;
}

.mt-auto {
  margin-top: auto;
}

.mt-3-vh {
  margin-top: 3vh;
}
