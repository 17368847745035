@import "../../../../../../styles/colorbox.scss";

.checkin-heading {
  font-size: 16px;
  font-weight: 600;
  color: $dark-grey6;
}

.mood-emoji {
  padding: 10px;
  border-radius: 10%;
  min-height: 3.3rem;
  border: 1px solid $light-blue;
  margin: 7px;
  cursor: pointer;
}

.client-satisfaction-meter .ant-modal-title {
  font-family: Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    "Helvetica Neue", sans-serif !important;
  font-size: 24px;
  font-weight: 700;
  color: $dark-grey6;
  flex: 1 1;
}

.active-mood {
  background-color: $light-green;
}

.submit-btn {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.submit-btn button {
  background-color: $blue;
  border: 1px solid $blue;
  font-weight: 700;
  text-align: center;
  vertical-align: center;
  font-size: 16px;
  color: white;
  padding: 6px 33px;
  padding-bottom: 30px;
}
