.popup-content {
  margin: auto;
  background: #fff;
  width: 50%;
  padding: 15px;
  border: 1px solid #d7d7d7;
}
[role="tooltip"].popup-content {
  width: 26%;
  box-shadow: 0px 17px 80px #c8d8e4;
  border-radius: 20px;
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}
.popup-arrow {
  -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  color: #fff;
  stroke-width: 2px;
  stroke: #d7d7d7;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.Heading {
  font-size: 16px;
  font-weight: bold;
  color: #5e656a;
}
.data {
  font-size: 16px;
  color: #5e656a;
}
