.scroll-to-top-button {
  background-color: white;
  border: 0px;
}

.scroll-to-top-container {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

@media (max-width: 450px) {
  .scroll-to-top-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: max-content;
  }

  .scroll-to-top-button {
    &.mb-100 {
      margin-bottom: 0;
    }
  }
}
