@import "./colorbox.scss";

.container-playbook {
  text-align: center;
  justify-content: space-around;
  box-sizing: border-box;
}

.reviewColorsContainer {
  margin: 50px 0;
  color: #1b3442;
  text-align: left;
}

.processParentContainer {
  font-family: "Poppins";
}

.processContainer {
  margin: 100px 10px 100px 10px;
}

.whatHappensContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.whereContainer {
  background: url("../assets/images/what-bg.png");
  padding: 25px 75px 25px 75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.whereBoxesContainer {
  display: flex;
  flex-direction: row;
}
.whereBoxContainer {
  display: flex;
  align-items: center;
}

.whereDosContainer {
  width: 284px;
  background: #ffffff;
  border: 2px dashed #c2d7cd;
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #5e656a;
  padding: 20px;
}

.badgesContainer {
  width: 100%;
}

.principlesContainer,
.whatContainer {
  margin: 50px 0;
  text-align: left;
}

.principlesContainer li {
  font-family: "Inter";
  color: $indigo-dye;
  font-size: 14px;
}

@media (max-width: 450px) {
  .principlesContainer,
  .whatContainer {
    padding: 0;
  }

  .principlesContainer li {
    font-size: 0.9rem;
    padding: 0;
  }

  .principlesContainer {
    margin: 0 auto;
    margin-top: 3.25rem;

    .processHeading2 {
      text-align: left;
    }
  }
}
