@import "../../../styles/colorbox.scss";

* {
  margin: 0;
  padding: 0;
}

.mobile-menu-container {
  position: absolute;
  top: 0;
  right: 0vw;
  height: 100vh;
  width: 0vw;
  overflow: hidden;
  max-height: 100%;
  background-color: $white;
  transition: width 400ms ease-in-out;
  z-index: 15;

  &.visible {
    width: 100%;
  }

  .main-page-mobile-navbar {
    display: block;
    width: 100%;

    nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4.3rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid $dark-grey3;

      .main-page-mobile-logo-container {
        width: 5.81rem;
        margin-left: 1.25rem;

        img {
          width: 5.81rem;
          cursor: pointer;
        }
      }

      .main-page-mobile-hamburger-menu {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 1.25rem;
        cursor: pointer;
      }
    }

    ul#links-container {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-bottom: 1.5rem;
        width: max-content;

        img {
          margin-right: 0.5rem;
        }

        a.nav-link {
          text-decoration: none;
          color: $black-color;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }
}
