@import "../../../../styles/colorbox.scss";
.project-details-wrapper {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  padding: 1em 3em 0 5em;
  margin-left: 12rem;
  margin-top: 4.1rem;
}
.project-phase-wrapper {
  background: white;
  min-height: 2rem;
}
.details-container {
  margin: 1em 0;
  min-height: 400px;
  border-radius: 5px;
}
.phase-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: $impure-black;
  &:hover {
    color: $french-blue;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
}
.ant-select-selection-item {
  font-weight: 600;
}
.ant-select-arrow {
  color: $impure-black;
}
.client-satisfaction-meter-button {
  display: flex;
  border: 0px;
  background: transparent;
}

.client-satisfaction-meter-text {
  margin-left: 1rem;
  color: $indigo-dye;
  font-weight: 500;
  font-size: 18px;
  &:hover {
    color: $french-blue;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
}
.satisfaction-icon {
  color: $gray;
}

.next-process-button {
  border-radius: 60px;
  margin-right: 1.5rem;
  border: 0px;
  padding: 1rem 2rem;
  justify-content: center;
  background-color: $dark-blue;
  display: flex;
  align-items: center;
}

.next-process-text {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: white;
  margin-left: 1rem;
  white-space: nowrap;
}

.bottom-bar {
  margin-bottom: 2.5vh;
  align-items: center;
}
.next-process-button-container {
  display: flex;
  justify-content: flex-end;
}

.next-process-button-disable {
  border-radius: 60px;
  margin-right: 1.5rem;
  border: 0px;
  padding: 1rem 2rem;
  justify-content: center;
  background-color: $disable-gray;
  display: flex;
  align-items: center;
}

.next-process-button-display {
  display: none;
}
