.sidebar {
  height: 100% !important;
  width: 300px;
  background-color: orange;
}

.phaseBox {
  padding: 40px;
  display: flex;
  justify-content: center;
  background: #fbf9f9;
  border: 1px solid #d7d7d7;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
}
.phaseBox:hover {
  background: rgba(13, 16, 53, 0.13);
  border: 1px solid #0d1035;
}
.phaseBoxActive {
  padding: 40px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  background: rgba(13, 16, 53, 0.13);
  border: 1px solid #0d1035;
}
.subPhaseBox {
  padding: 28px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  justify-content: center;
  font-size: 14px;
  background: #fbf9f9;
  border: 1px solid #d7d7d7;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
}
.subPhaseBox:hover {
  background: rgba(97, 202, 176, 0.09);
  border: 1px solid #61cab0;
}
.subPhaseBoxActive {
  padding: 28px;
  font-weight: 500;
  font-style: normal;
  display: flex;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  background: rgba(97, 202, 176, 0.09);
  border: 1px solid #61cab0;
}

.activityBox {
  padding: 28px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border-bottom: 0.1px solid #d7d7d7;
}
.activityBox:hover {
  border-bottom: 2px solid #61cab0;
}

.delete_btn {
  color: red;
  cursor: pointer;
}

.checkBox {
  margin-top: 56px !important;
  border: 2px solid #61cab0;
  width: 700px;
  border-radius: 7px;
}
.activityBoxActive {
  padding: 28px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border-bottom: 2px solid #61cab0;
}

/* .files {
  margin-top: 60px;
  margin-right: 56px;
  margin-left: 30px;
} */
.card {
  border: "3px solid";
  border-radius: 2px solid black;
}
.fileIcon {
  display: flex;
  justify-content: center;
  font-size: 650%;
}
.uploadButton {
  display: flex;
  justify-content: flex-end;
  padding-right: 90px;
  padding-top: 40px;
}

.uploadFileButton {
  display: flex;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 550;
  font-size: 14x;
  line-height: 25px;
  padding: 5px 35px;
  background: #f9f9f9;
  border: 1px solid #0d1035;
  border-radius: 3px;
}
.create_project {
  display: flex;
  justify-content: flex-end;
}
.submit_button {
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 20px;
  background: #cbd0cf;
  font-weight: 550;
  font-size: 14x;
  line-height: 25px;
  padding: 5px 50px;
  border: 1px solid #0d1035;
  border-radius: 5px;
}
.submit_button_green {
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 20px;
  background: #61cab0;
  color: white;
  font-weight: 550;
  font-size: 14x;
  line-height: 25px;
  padding: 5px 50px;
  border: 1px solid #0d1035;
  border-radius: 5px;
}

.main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px;
  background-color: white;
  height: fit-content;
}

.project_status {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 25px;
  padding: 5px 35px;
  background: #f9f9f9;
  border: 1px solid #0d1035;
  box-sizing: border-box;
  border-radius: 3px;
  text-transform: capitalize;
  text-decoration: none;
}
.content {
  font-family: "poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170.52%;
  padding: 4px;
  text-transform: capitalize;
  text-decoration: none;
  color: #00244a;
}

.section {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-right: 44px;
  cursor: pointer;
}

.guidelinesSection {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}
.selectedSection {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-right: 44px;
  border-bottom: 2px solid #61cab0;
  cursor: pointer;
  color: #61cab0;
}
.process-details-wrapper {
  height: 100%;
}
/* .nextProcess {
  display: flex;
  justify-content: flex-end;
  max-width: 1500px;
}
.nextProcessButton {
  padding: 20px 40px;
  background-color: #4b4b4b;
  color: white;
  border-radius: 5px;
  cursor: pointer;
} */
.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.stageName {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
  color: #0d1035;
}
.create_button {
  background: #61cab0;
  mix-blend-mode: normal;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-transform: capitalize;
}
.date {
  font-family: "poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170.52%;
  text-align: center;
  padding: 4px;
  background-color: #f8f9f9;
  border-radius: 5px;
  text-transform: capitalize;
  text-decoration: none;
  color: #00244a;
  margin-right: 4px;
}
