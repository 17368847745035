@import "../../styles/colorbox.scss";

.guidelines-section-wrapper {
  overflow: scroll;
  z-index: 2;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  margin-left: 40%;
  background-image: url("../../assets/images//Gradient.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(400px);
  padding-left: 0;
  padding-right: 0;
}

.container {
  box-sizing: border-box;
  right: 0;
  width: 60%;
  height: 100%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@keyframes right-to-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.file-container {
  display: flex;
  align-items: center;
}

.file-content {
  margin: 0 1em 0 1em;
  text-decoration: underline;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $navy-blue;
  word-break: break-word;
}

.closeButton {
  cursor: pointer;
  border: 1px solid $light-gray;
  border-radius: 15px;
  padding: 3px 10px;

  &:hover {
    border: 1px solid $red-pill-button;
  }
}

.guidelines-section-wrapper {
  &.pl-24,
  .pr-24 {
    padding-left: 0;
    padding-right: 0;
  }
}

.guidancesContainer,
.processChecklist,
.whatContainer,
#parbatContainer {
  padding: 0 3.75rem;
}

@media (max-width: 450px) {
  .guidelines-section-wrapper {
    z-index: 10;
    &.pl-24,
    .pr-24 {
      padding: 0;
    }
  }
}
