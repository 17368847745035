@import "../../../styles/colorbox.scss";

nav#mobile-navbar-bottom {
  display: none;
}

@media (max-width: 450px) {
  nav#mobile-navbar-bottom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 7.5rem;
    background: linear-gradient(
      180deg,
      $bottom-navbar-color 3.99%,
      $white 33.12%
    );

    & > * {
      margin: 0;
      padding: 0;
    }
  }
}
