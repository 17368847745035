@import "./src/styles/colorbox";

.sidebar-item {
  padding: 1em;
  padding-left: 1.7rem;
  cursor: pointer;
  &:hover {
    background-color: $light-white;
  }
}

.project-title {
  color: $navy-blue;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.8rem;
}
.project-info {
  color: $navy-blue;
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.1rem;
  margin: 0.5em 0;
}

.project-status {
  @extend.project-info;
  color: red;
}

.active-sidebar-item {
  background: $light-white;
  border-left: 7px solid $navy-blue;
  cursor: auto;
}
