@import "../../styles/colorbox.scss";

.right-arrow-line {
  width: 100%;
  border-top: 0.5px solid $gray;
  flex: 1 1 180px;
}

.right-arrow-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &.width-100 {
    width: 100px;
    flex: 1 1 100px;
  }
  &.width-180 {
    flex: 1 1 180px;
  }
  color: $indigo-dye;
}

@media (max-width: 450px) {
  .right-arrow-container {
    &.width-100 {
      transform: rotate(90deg);
      width: 30px;
      width: 30px;
      flex: 1 1 30px;
    }
    &.width-180 {
      width: 1.25rem;
      flex: 1 0 1.25rem;
    }
  }
}
