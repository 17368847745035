@import "../../styles/colorbox.scss";

.story {
  cursor: pointer;
}
.little-story-container {
  display: flex;
  justify-content: flex-end;
  margin: 1vw;
}
.crown {
  margin: 1rem;
  cursor: pointer;
}
