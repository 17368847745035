.guidanceMainHeadingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reqsInnerBox {
  background: rgba(40, 255, 126, 0.06);
  border-radius: 12px;
  padding: 12px;
  text-align: left;
}

.statusReport__statusContainer {
  display: flex;
  align-items: center;
}
.statusReport__labelContainer {
  margin-left: 20px;
  background: #1b3442;
  border-radius: 8px;
  padding: 12px;
  display: flex;
}

.statusReport__budget {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  flex-direction: column;
  border-radius: 10px;
}

.statusReport__budgetPurple {
  background: rgba(245, 225, 255, 0.4);
  padding: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
}
.budgetHeadingPurple {
  color: #000;
  margin-right: 140px;
  position: relative;
}
.budgetHeadingPurple:after {
  width: 60px;
  background: #ffec3e;
  height: 60px;
  content: "";
  display: block;
  position: absolute;
}
.budgetHeadingPurple:after {
  right: -100px;
  top: -17px;
}

.statusReport__budgetRed {
  background: rgba(255, 228, 228, 0.6);
  padding: 16px;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  position: relative;
}
.budgetHeadingRed {
  color: #000;
  margin-right: 197px;
  position: relative;
}
.budgetHeadingRed:after {
  width: 60px;
  background: #f16262;
  height: 60px;
  content: "";
  display: block;
  position: absolute;
}
.budgetHeadingRed:after {
  right: -151px;
  top: -17px;
}

.statusReport__budgetYellow {
  background: rgba(255, 243, 225, 0.6);
  padding: 16px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: row;
  position: relative;
}
.budgetHeadingYellow {
  color: #000;
  margin-right: 172px;
  position: relative;
}
.budgetHeadingYellow:after {
  width: 60px;
  background: #6bde92;
  height: 60px;
  content: "";
  display: block;
  position: absolute;
}
.budgetHeadingYellow:after {
  right: -129px;
  top: -17px;
}

.statusReport__blueBox {
  background: rgba(40, 160, 255, 0.06);
  border-radius: 10px;
  margin-bottom: 5px;
  height: 100%;
  padding: 16px;
  display: flex;
}

.blueBox__heading {
  margin-right: 60px;
}

.blueBox__placeholder {
  color: rgba(40, 160, 255, 0.5);
}

.processContainer {
  margin-top: 50px;
  padding: 40px;
  background: #ffffff;
  color: #000;
  border-radius: 10px;
}

@media (max-width: 450px) {
  li::marker,
  li {
    font-size: 0.9rem !important;
  }

  .statusReport__statusContainer {
    flex-direction: column-reverse;
  }

  .statusReport__labelContainer {
    margin-top: 3.5rem;
    margin-bottom: 1.25rem;
  }

  .statusReport__statusContainer b {
    margin-bottom: 2.75rem;
  }

  .statusReport__budgetYellow {
    order: 1;
  }
  .statusReport__budgetPurple {
    order: 2;
  }

  .statusReport__budgetRed {
    order: 3;
  }

  .statusReport__budgetYellow,
  .statusReport__budgetPurple,
  .statusReport__budgetRed {
    flex-direction: column;
  }

  .statusReport__budgetPurple {
    background: #fffef5;
  }

  .statusReport__budgetYellow {
    background: #f7fdf9;
  }

  .budgetHeadingYellow:after,
  .budgetHeadingPurple:after,
  .budgetHeadingRed:after {
    display: none;
  }

  .budgetHeadingYellow,
  .budgetHeadingPurple,
  .budgetHeadingRed {
    margin-left: 2rem;
  }

  .budgetHeadingPurple::before,
  .budgetHeadingYellow::before,
  .budgetHeadingRed::before {
    width: 19.51px;
    height: 18.75px;
    content: "";
    position: absolute;
    border-radius: 5px;
    left: -2rem;
    top: 0.25rem;
  }

  .budgetHeadingPurple::before {
    background: #ffec3e;
  }

  .budgetHeadingYellow::before {
    background: #6bde92;
  }

  .budgetHeadingRed::before {
    background: #f16262;
  }

  .statusReport__blueBox {
    flex-direction: column;
  }
}
