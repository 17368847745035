@import "./src/styles/colorbox";

.header {
  display: flex;
  height: 65px;
  border: 1px solid #d7d7d7;
  padding: 1em;
  width: 100%;
  justify-content: space-between;
}
.logo {
  padding: 0.5em 1.35rem;
  font-size: 1.5rem;
  font-weight: 800;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  box-shadow: 4px 1px 23px -16px rgba(0, 0, 0, 0.5);
}
.playbook-title-dot {
  color: $oceanBlue-color;
  padding: 0 0 0 3px;
}

.header-list-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.navbar-items-wrapper {
  list-style: none;
}
.navbar-item {
  font-size: 0.9rem;
  color: $black-color;
  margin: 0.5rem 1rem;
}

.navbar-link {
  display: block;
  color: $black-color;
  text-decoration: none;
  margin-bottom: 0.95rem;
  font-family: "Inter";
}

.navbar-link:hover {
  color: $french-blue;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.user-account-actions {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: max-content;
  position: relative;
  top: -0.3rem;

  .avatar {
    img {
      opacity: 0.7;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  ul.dropdown {
    list-style-type: none;
    position: relative;
    height: auto;
    min-width: 16.3rem;
    top: 0.5rem;
    left: -6.7rem;
    padding: 1.5rem;
    background: $white;
    border: 1px solid $light-grey;
    box-shadow: 0px 4px 30px 6px rgba($shadow-black, 0.2);
    border-radius: 22px;

    img {
      height: 44px;
      width: 44px;
      border-radius: 50%;
    }

    .introduction-container {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      .name-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 1rem;

        h4.name {
          font-weight: 600;
          font-size: 0.9rem;
          margin: 0;
          margin-bottom: 4px;
        }

        p.role {
          font-weight: 400;
          font-size: 0.9rem;
          margin: 0;
        }
      }
    }

    hr.section-separator {
      width: 100%;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .roles {
      li {
        text-align: left;
        font-weight: 500;
        font-size: 0.9rem;
        margin-bottom: 1rem;
        transition: color 0.3s;

        &:hover {
          cursor: pointer;
          color: $ant-design-blue;
        }
      }
    }

    div.logout-link {
      font-weight: 500;
      font-size: 0.9rem;

      li {
        transition: color 0.3s;

        &:hover {
          cursor: pointer;

          a {
            color: $red-basic;
          }
        }

        a {
          color: inherit;
          text-decoration: none;
          display: block;
          width: 100%;
        }
      }
    }
  }
}

.active-navbar-item {
  border-bottom: 3px solid $french-blue;
  font-weight: 700;
}

.dashboard-navbar {
  z-index: 3;
  position: fixed;
  left: 0;
  background: white;
  top: 0;
  width: 100%;
}

.logo-dashboard-nav {
  font-size: 1.5rem;
  margin-top: -0.3rem;
}

.header-main-items-wrapper {
  margin-left: 2rem !important;
}
