@import "../../../styles/colorbox.scss";
@import url("https://fonts.googleapis.com/css?family=Archivo");
@import url("https://fonts.googleapis.com/css?family=Poppins");

.main-content-align {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lead-content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170.52%;
  letter-spacing: 0.01em;
  color: $indigo-dye;
}

.happens {
  font-family: "Archivo";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 29px;
  margin-top: 120px;
  color: $bg-blue;
  letter-spacing: 0.5px;
  text-align: center;
}

.button-state,
.button-state-active,
.happens,
#dos-container {
  min-width: 12.5rem;
}

.button-state,
.button-state-active {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    width: max-content;
    font-size: 0.875rem;
  }
}

#dos-container {
  ul {
    padding-left: 1.75rem;
  }
  b,
  li {
    font-size: 0.85rem;
  }
}

.what-happens-description {
  font-family: "Inter";
  color: $indigo-dye;
  letter-spacing: 1px;
  line-height: 27px;
  margin-top: 1.5rem;
  text-align: center;
}

.button-state-active {
  width: 17rem;
  flex: 2 1 auto;
  border-style: 0px solid;
  padding: 1rem 1.75rem;
  border-width: 0.5px;
  background-color: $light-red;
  border-radius: 60px;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: column;
  line-height: 1.5rem;
  display: flex;
  justify-content: center;
  font-size: 17px;
  line-height: 18px;
  text-align: center;
  color: $red-pill-button;
  cursor: pointer;
  line-height: 23px;
}

.button-state {
  width: 17rem;
  flex: 2 1 auto;
  flex-direction: column;
  display: flex;
  background-color: $grey-white;
  justify-content: center;
  padding: 1rem 1.75rem;
  border-width: 0.5px;
  border: 0px solid;
  border-radius: 60px;
  font-family: "Poppins";
  font-style: normal;
  font-size: 17px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  color: $impure-black;
  line-height: 23px;
  border: 1px solid $white;

  &:hover {
    border: 1px solid $red-pill-button;
  }
}

.button-state span {
  font-weight: 600;
}

.button-state-active span {
  font-weight: 800;
}

.dos {
  font-size: 16px;
  font-family: "Inter";
  letter-spacing: 0.5px;
  line-height: 27px;
  text-decoration: none;
  color: $bg-blue;
  cursor: pointer;
}
.dos-points-guideline {
  color: $bg-blue;
  text-decoration: underline;

  a {
    transition: none;
    color: $bg-blue;
    text-decoration: underline;
  }
}

.dos-points-guideline:hover {
  color: red;
  text-decoration: underline;

  a {
    transition: none;
    color: red;
    text-decoration: underline;
  }
}

#dos-parent-container {
  display: flex;
  justify-content: center;
}

.dos-container {
  border-radius: 30px;
  padding: 2.5rem 1.5rem;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $light-grey;
}
.develop-heading {
  display: inline-block;
}
li::marker {
  margin-top: 24px;
  font-size: 1.5rem;
  //font-weight: bolder;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.desktop-hidden {
  display: none;
}

.close-button-container {
  position: unset;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: $white-translucent;
  height: 4rem;
  align-items: center;
  width: 100%;
  padding: 0 3.75rem;
  z-index: 1;
}

.empty-use {
  text-align: center;
}

.reviewColorsContainer {
  .row {
    width: max-content;
    .boxes-container {
      display: flex;
      padding: 0;

      .col-3 {
        padding-left: 0;
        width: max-content;
        margin-right: 3rem;
      }
    }

    hr {
      width: 83%;
      position: relative;
      top: -3px;
      z-index: -1;
    }
  }
}

#lead-conversion-checklist {
  .row {
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-left: 0.1rem;

    // Scrollbar dimensions for Firefox
    scrollbar-width: thin;

    // Scrollbar dimensions for other browsers
    &::-webkit-scrollbar {
      height: 6px;
    }

    .col-4 {
      width: auto;
      margin-bottom: 1rem;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        .leadConversionChecklist,
        .leadConversionChecklistActive {
          margin-right: 0;
        }
      }
    }
    .leadConversionChecklist,
    .leadConversionChecklistActive {
      padding-left: 0;
      padding-right: 0;
      margin-right: 5rem;
    }
  }

  .col-12 {
    &.horizontal-rule {
      flex-basis: 100%;
      position: relative;
      top: -2.45rem;

      hr {
        width: 98.5%;
      }
    }
  }

  .processHeading2 {
    margin-bottom: 6rem;
  }
}

#review-estimates-container {
  padding: 0 3.75rem;
}

@media (max-width: 450px) {
  .main-page-header,
  div.whitespace,
  .mobile-hidden {
    display: none;
  }

  .desktop-hidden {
    display: block;
  }

  .row {
    padding: 0;
    margin: 0;
  }

  #intro-row > *,
  #principles-row > * {
    padding: 0;
    margin: 0;
  }

  #intro-row {
    #intro-container {
      width: 90%;
      padding: 0;
      margin: 0 auto;

      .main-heading-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;

        h1 {
          margin: 0;
          padding: 0;
          font-size: 1.5rem;
          line-height: 1.5rem;
        }

        img {
          margin-right: 1rem;
        }
      }

      .lead-content {
        p {
          font-size: 0.9rem;
          line-height: 1.5rem;
          text-align: left;
        }
      }
    }
  }

  #principles-row {
    .width-90 {
      width: 90%;
      padding: 0;
      margin: 0 auto;

      .col-10 {
        padding: 0;
        width: 100%;
      }

      h1.processHeading2 {
        margin-bottom: 1.5rem;
      }

      .mobile-flex {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        img {
          margin-right: 1rem;
          height: 2.25rem;
          width: 2.25rem;
        }

        .processHeading4 {
          margin: 0;
          font-size: 1.125rem;
        }
      }
    }
  }

  div.happens {
    margin: 0;
    font-size: 1.5rem;
  }

  p.what-happens-description {
    margin: 0;
    margin-top: 0.9rem;
    margin-bottom: 1.5rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  #linkWhatHappen {
    padding: 0;
    width: 90%;
    margin: 0 auto;

    &.main-content-align {
      margin-top: -2rem !important;
    }

    div.happens {
      margin-top: 4.5rem;
    }

    #what-happens-buttons-container[data-id="polishing"],
    #what-happens-buttons-container[data-id="release"] {
      justify-content: center;
    }

    #what-happens-buttons-container[data-id="polishing"],
    #what-happens-buttons-container[data-id="release"] {
      justify-content: center;
    }

    #what-happens-buttons-container {
      width: 100%;
      margin: 0 auto;
      margin-top: 2.5rem;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;

      // Scrollbar dimensions for Firefox
      scrollbar-width: none;

      // Scrollbar dimensions for other browsers
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .button-state-active,
    .button-state {
      min-width: auto;
      width: max-content;
      max-width: 13rem;
      height: 40px;
      font-size: 0.75rem;
      padding: 1rem 1.25rem;
      margin-bottom: 1rem;
      span {
        width: max-content;
        max-width: 13rem;
      }
    }

    .right-arrow-container {
      position: relative;
      top: -7px;
    }
  }

  #dos-parent-container {
    width: 100%;
    margin: 0;
    padding: 0;

    #dos-container {
      width: 75%;

      margin: 0 auto;
      padding: 1.5rem 1.875rem;

      b {
        font-size: 1.125rem;
      }

      ul {
        margin-top: 0.5rem;

        .dos-points-guideline {
          font-size: 0.9rem;
        }
      }
    }
  }

  #mobile-navbar-bottom {
    .whatHappenButtonContainer {
      img {
        width: clamp(8.5rem, 45vw, 11rem);
        margin: 0;
        padding: 0;
      }
    }
    .little-story-container {
      img {
        width: clamp(8.5rem, 45vw, 11rem);
        margin: 0;
        padding: 0;
      }
    }
  }

  .guidelines-section-wrapper {
    width: 100%;
    margin: 0;

    .flex {
      width: 100%;
      flex-direction: row-reverse;

      &.close-button-container {
        width: 100%;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background-color: $white-translucent;
        padding: 0;
        height: 3rem;
        .closeButton {
          margin-top: 0;
          padding: 0;
          border: 0;
          align-self: center;
          img {
            height: 13px;
            width: 13px;
            margin-right: 1rem;
          }
        }
      }
    }

    #parbatContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      margin-top: 2rem;

      .col-6 {
        width: 100%;
        padding: 0;
      }
    }

    .estimatesContainer {
      padding: 0;

      .col-1 {
        width: 0;
      }

      .col-10 {
        padding: 0;
        width: 100%;
      }

      .whatImg {
        margin: 0;
        margin-top: 2rem;
      }

      .reviewColorsContainer {
        .row {
          width: 100%;
          justify-content: space-between;
          flex-wrap: nowrap;
          flex-direction: column;

          overflow-x: auto;

          // Scrollbar dimensions for Firefox
          scrollbar-width: none;

          // Scrollbar dimensions for other browsers
          &::-webkit-scrollbar {
            display: none;
          }

          .boxes-container {
            display: flex;
            padding: 0;

            .col-3 {
              width: max-content;
              margin-bottom: 1rem;
              margin-right: 0;

              & > * {
                max-width: 12rem;
                padding: 1.5rem;
              }
              .reviewColorBox1 {
                padding-left: 0;
              }
            }
          }

          hr {
            display: none;
          }
        }
      }
    }

    .handoverContainer,
    .retroContainer {
      .col-3 {
        width: 50%;
        max-width: 10rem;

        .guidance {
          margin: 0;
          margin-bottom: 1rem;

          img {
            margin-right: 0.5rem;
          }
        }
      }
    }

    #lead-conversion-checklist {
      .row {
        width: 100%;
        overflow-x: auto;
        flex-wrap: nowrap;

        // Scrollbar dimensions for Firefox
        scrollbar-width: none;

        // Scrollbar dimensions for other browsers
        &::-webkit-scrollbar {
          display: none;
        }

        .col-4 {
          width: max-content;
          margin-bottom: 1rem;
          padding: 0;

          &:first-of-type {
            .leadConversionChecklist,
            .leadConversionChecklistActive {
              padding-left: 0;
            }
          }

          .leadConversionChecklist,
          .leadConversionChecklistActive {
            padding: 1.5rem;
            margin: 0;
            width: max-content;
          }

          .leadConversionChecklist {
            &:hover {
              font-weight: 600;
              color: $dark-grey2;
              border-bottom: 1px solid $gray-light;
            }
          }
        }

        .col-12 {
          display: none;
        }
      }

      h1.processHeading2 {
        text-align: left;
        margin-bottom: 3rem;
      }
    }

    .processChecklist {
      .row {
        overflow-x: auto;
        flex-wrap: nowrap;

        // Scrollbar dimensions for Firefox
        scrollbar-width: none;

        // Scrollbar dimensions for other browsers
        &::-webkit-scrollbar {
          display: none;
        }

        .col-4 {
          width: max-content;
          margin-bottom: 1rem;
        }
      }
    }
  }

  .popup-content {
    width: 80% !important;
    max-width: 20rem !important;
    left: 0 !important;
    margin-left: 2rem !important;
    height: 40vh !important;
    min-height: 20rem;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    padding: 1.5rem !important;

    // Scrollbar dimensions for Firefox
    scrollbar-width: thin;

    // Scrollbar dimensions for other browsers
    &::-webkit-scrollbar {
      width: 4px;
    }
  }

  #popup-8 {
    bottom: 20vh;
  }

  .mobile-menu-container {
    max-height: 100%;
  }

  #review-estimates-container {
    padding: 0 1.5rem;
  }
}

@media (max-width: 330px) {
  .handoverContainer,
  .retroContainer {
    .col-3 {
      width: 50%;
      max-width: 10rem;

      .guidance {
        margin: 0;
        margin-bottom: 1rem;
        flex-direction: column;

        img {
          margin-right: 0.5rem;
        }

        .guidance-key,
        .guidance-values {
          align-self: center;
        }
      }
    }
  }

  #lead-conversion-checklist {
    * {
      font-size: 99% !important;
    }
  }
}
