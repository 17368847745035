.desktop-hidden {
  display: none;
}

@media (max-width: 450px) {
  .whitespace,
  .mobile-hidden {
    display: none;
  }

  .desktop-hidden {
    display: block;
  }

  .row {
    margin: 0;
    padding: 0;

    &.mt-88 {
      margin: 0;
    }

    .col-5 {
      width: 90%;
      padding: 0;
      margin: 0 auto;

      .mobile-flex {
        display: flex;
        align-items: center;
        margin-bottom: 2.25rem;

        img {
          margin-right: 1rem;
        }

        .processHeading2 {
          margin: 0;
        }
      }
    }
  }

  .principlesContainer {
    .col-10 {
      padding: 0;
      width: 90%;
      margin: 0 auto;

      img.desktop-hidden {
        display: block;
        height: 2.25rem;
        width: 2.25rem;
        margin-right: 1rem;
      }

      h1.processHeading4 {
        margin: 0;
      }
    }
  }

  #linkWhatHappen {
    padding: 0;
    width: 98%;
    margin: 0 auto;

    &.main-content-align {
      margin-top: 3rem;
    }
  }

  #what-happens-buttons-container[data-id="polishing"],
  #what-happens-buttons-container[data-id="release"] {
    margin-top: 2.5rem;
    overflow-y: hidden;
    overflow-x: auto;

    .button-state-active {
      span {
        width: auto;
        max-width: 13rem;
      }
    }
  }
}
