.hero {
  color: #fff;
  background-color: #0e0f37;
  height: 50vh;
  width: 50vh;
  overflow: hidden;
}
.signInImage {
  width: 10px;
  overflow: hidden;
  height: 90px;
  background-image: url("../../assets/icons/maskGroup.png");
}

.pageContent {
  margin-top: 200px;

  width: 50%;
}

.login {
  border-radius: 2px solid;
  display: flex;
  justify-content: center;
}
.heading {
  display: flex;
  justify-content: center;
  font-family: "Archivo";
  font-size: 60px;
  font-weight: 600;
  line-height: 1.23;
  letter-spacing: 0.03em;
  margin: 10px;
}

.buttonContainer {
  margin: 20px 10px 30px 10px;
}

.button {
  font-family: "Inter", sans-serif;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.01em;
  margin-right: 20px !important;
}

.button__blue {
  background-color: #28a0ff;
}

.button__green {
  background-color: #24cdaf !important;
  border: 1px solid #24cdaf !important;
}

.heroProcessContainer {
  margin: 100px 400px 100px 400px;
}
.processBar {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-self: stretch;
  flex-wrap: wrap;
  padding: 40px;
  background: #ffffff;
  border-radius: 10px;
}

.subProcessBar {
  margin-top: 20px;
  background: white;
  /* padding: 20px 60px; */
  color: black;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.01em;
  border-radius: 30px;
  word-break: break-all;
}

.processBarLabel {
  color: #0e0f37;
  font-family: Inter;
  font-style: normal;
  font-size: 16px;
  font-weight: 500 !important;
  margin: 0px 50px;
  cursor: pointer;
}

.processBarLabelActive {
  color: #28a0ff;
  font-family: Inter;
  font-style: normal;
  font-size: 16px;
  font-weight: 900 !important;
  margin: 0px 50px;
  cursor: pointer;
}

.subProcessLabel {
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  margin: 5px 20px;
  display: inline;
  color: #0e0f37;
}
.subProcessLabel:hover {
  color: #24cdaf;
}

.subProcessLabelActive {
  color: #24cdaf;
  font-weight: bold !important;
  margin: 0px 20px;
}
.subProcessLabelActive:hover {
  color: #24cdaf;
}

.processContainer {
  margin-top: 50px;
  padding: 40px;
  background: #ffffff;
  color: #000;
  border-radius: 10px;
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}
.logo {
  position: absolute;
}
.logoImage {
  max-width: 60%;
  height: auto;
  margin-left: 3rem;
  margin-top: 1rem;
  cursor: pointer;
  filter: brightness(0) invert(1);
}

.loginPageImage {
  width: 50%;
  box-shadow: 4px 0px 60px rgb(0 0 0 / 10%);
}

.loginPageContainer {
  height: 100vh;
}

.welcomeContent {
  font-family: "Inter";
  text-align: center;
  line-height: 2rem;
}
