.w-20 {
  width: 20px;
}
.w-28 {
  width: 28px;
}
.w-10 {
  width: 10px;
}

.w-12 {
  width: 12px;
}
.w-18 {
  width: 18px;
}

.w-30 {
  width: 30px;
}

.w-40 {
  width: 40px;
}

.w-16\% {
  width: 16%;
}
.w-22\% {
  width: 22%;
}
.w-26\% {
  width: 26%;
}
.w-32\% {
  width: 32%;
}
.w-38\% {
  width: 38%;
}
.w-42\% {
  width: 42%;
}
.w-55\% {
  width: 55%;
}

.w-65\% {
  width: 65%;
}
.w-70\% {
  width: 70%;
}
.w-71\% {
  width: 71%;
}

.w-85\% {
  width: 85%;
}
.w-98\% {
  width: 98%;
}
.w-100\% {
  width: 100%;
}

.h-20 {
  height: 20px;
}
.h-30 {
  height: 30px;
}
