.whatHappenButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin: 1vw;
}

.what-happens-button {
  border: 0px;
  background-color: white;
}
