@import "src/styles/variables";

.btn-custom {
  height: 48px;
  padding: 0 2em 0 2em;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
}
.btn-success-custom {
  color: white;
  background: $btn-success-color;
}
.btn-success-custom:hover {
  background: $btn-success-hover-color;
}
.btn-primary-custom {
  color: black;
  border: 1px solid black;
  background: $btn-primary-color;
}
.btn-primary-custom:hover {
  background: $btn-primary-hover-color;
}

.btn-danger-custom {
  color: white;
  background: $btn-danger-color;
}
.btn-danger-custom:hover {
  background: $btn-danger-hover-color;
}