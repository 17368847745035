@import "../../../../../styles/colorbox.scss";

.update-list-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.date {
  color: $impure-black;
  margin-top: 32px;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.guideline {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-left: 0.5rem;
  line-height: 17px;
  margin-top: 24px;
  padding-top: 24px;
  cursor: pointer;
}
.guideline:hover {
  background-color: #f4f4f4;
  border-radius: 10px;
}

.guidelineDate {
  display: flex;
  flex: 4 1;
  margin-left: 1rem;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  margin-right: 1rem;
  color: #979797;
}
.guideline-name {
  &:hover {
    color: $french-blue;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
}
.updateSection {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.saveButton {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -1.30385e-9px;
  text-transform: uppercase;
  color: #61cab0;
  cursor: pointer;
}

.cancelButton {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -1.30385e-9px;
  text-transform: uppercase;
  color: #979797;
  cursor: pointer;
}

.updateTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 1.5rem;
  line-height: 24px;
  color: $impure-black;
}

.commentSection {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 80px;
}
.guidelineDesc {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
}
.guidelineContent {
  color: $impure-black;
  font-size: 14px;
  font-family: "Inter";
  line-height: 20px;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: $french-blue;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
}
.guidelineSpace {
  flex: 0.5;
}

.guidelineDescContainer {
  margin-top: 0.9rem;
  flex: 1 1;
  margin-right: 1rem;
}

.guideline-name {
  font-family: "Inter";
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-size: 16px;
  color: $impure-black;
  margin-top: 4px;
}

.side-update-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $impure-black;
}
