@import "../../../styles/colorbox.scss";

.headingMainPage {
  display: flex;
  justify-content: center;
  font-family: "Archivo";
  font-size: 7.4vh;
  font-weight: 700;
  line-height: 1.23;
  letter-spacing: 0.03em;
  text-align: center;
  color: $bg-blue;
}
.main-page-logo {
  position: absolute;
  width: 13.98px;
  height: 14.11px;
  left: 54.57px;
  top: 6.27px;
  color: $navy-blue;
}
.button-container-main-page {
  display: flex;
  justify-content: center;
}
.button-container-main-page .button {
  max-width: 11rem;
  min-width: 11rem;
}
.button-container-main-page .ant-btn-primary {
  border-color: $gray-outline;
}

.button {
  font-family: "Inter", sans-serif;
  font-weight: 500 !important;
  font-size: 1rem !important;
  height: 60px !important;
  line-height: 17px !important;
  letter-spacing: 0.01em;
  background-color: white;
  color: black;
}
.arrow-line {
  width: 100px;
  margin-top: 30px;
  border-top: 0.5px solid #979797;
}
.arrowTop,
.arrowBottom {
  margin-left: 42%;
}
.illustrationContainer {
  margin-left: 40%;
  width: 22%;
}
.lifeCycleContainer {
  display: flex;
  justify-content: center;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  line-height: 1.23;
  letter-spacing: 0.03em;
  margin: 10px;
  text-align: center;
  color: $navy-blue;
  background-color: #ebe8e8;
  margin-left: 40%;
  margin-right: 40%;
  border-radius: 40px;
  padding: 19px 20px;
  margin-top: 6.75vh;
}
.logoContainerMainPage {
  display: flex;
  padding-top: 1rem;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 1.8rem;
  margin-left: 3rem;
}

.main-page-container {
  height: 99.5vh;
  width: 100%;
  background-image: url("../../../assets/images/Gradient.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(400px);
}

.outerContainerMainPage {
  margin-right: 16px;
}

.menuItemMainPage {
  font-weight: 600;
  font-family: Poppins;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 2px;
  text-decoration: none;
  color: $impure-black;
  transition: filter 0.15s cubic-bezier(1, 0, 0, 1);
}

.menuItemMainPage:hover {
  filter: invert(40%) sepia(80%) saturate(1217%) hue-rotate(191deg)
    brightness(110%) contrast(101%);
}

.arrowDropdownCircleIcon {
  color: black !important;
}

.hamburgerMenuContainerMainPage {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 28px;
  align-items: flex-end;
}

.menuItemMainPageContainer {
  margin-top: 1.25rem;
}

.hamburgerMenuOpenIcon {
  width: 26.5px;
  margin-top: 1rem;
  margin-right: 1.2rem;
  cursor: pointer;
}

.hamburgerMenuCloseIcon {
  width: 45px;
}

.headingContainerMainPage {
  margin-top: 6%;
}
.maintenance-button-container {
  display: flex;
  margin-left: 24rem;
  justify-content: center;
  flex-direction: row;
}

.arrows-container-main-page {
  margin-left: 24rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.up-arrow-main-page {
  margin: 1rem;
}

.down-arrow-main-page {
  margin: 1rem;
  margin-left: 7rem;
}

.button-container-main-page button:hover {
  border: 2px solid $red-pill-button;
  background-color: white;
  color: $indigo-dye;
}

.button-container-main-page button:active {
  border: 2px solid $red-pill-button;
  background-color: $red-pill-button;
  color: white;
}

.button-container-main-page button:focus {
  border: 2px solid $red-pill-button;
  background-color: $red-pill-button;
  color: white;
}

.maintenance-button-container button:focus {
  border: 2px solid $red-pill-button;
  background-color: $red-pill-button;
  color: white;
}

.maintenance-button-container button:active {
  border: 2px solid $red-pill-button;
  background-color: $red-pill-button;
  color: white;
}
.maintenance-button-container button:hover {
  border: 2px solid $red-pill-button;
  background-color: white;
  color: $indigo-dye;
}

.main-page-mobile-navbar {
  display: none;
}

.main-page-mobile-illustration {
  display: none;
}

#arrow-right,
#arrow-left {
  display: none;
}

@media (max-width: 300px) {
  .main-page-container {
    position: relative;
    top: -1.5rem;
    padding-top: 1.5rem;
    height: 100%;
  }

  .overflow-hidden {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .main-page-mobile-illustration {
    width: 100%;
    display: flex;
    margin-bottom: 4.375rem;
  }

  #cyclic-arrows-container {
    position: relative;
    top: 12.8rem;
    width: 15.2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: -12.2rem;

    #arrow-right,
    #arrow-left {
      display: flex;
      width: 3rem;
    }

    #arrow-left {
      transform: rotate(180deg);
    }
  }

  .main-page-container {
    display: flex;
    flex-direction: column;
  }
  .main-page-mobile-navbar {
    display: block;

    nav {
      background-color: initial;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4.3rem;
      margin-bottom: 4rem;

      .main-page-mobile-logo-container {
        width: 5.81rem;
        margin-left: 1.25rem;

        img {
          width: 5.81rem;
          cursor: pointer;
        }
      }

      .main-page-mobile-hamburger-menu {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 1.25rem;
        cursor: pointer;
      }
    }
  }

  .logoContainerMainPage {
    display: none;
  }

  .outerContainerMainPage {
    width: 100%;
    margin: 0;
  }

  .headingContainerMainPage {
    width: 90%;
    margin: 0 auto;

    .headingMainPage {
      margin: 0 auto;
      font-size: clamp(2rem, 0.5rem + 10vw, 3.125rem);
      font-weight: 700;
      line-height: clamp(2rem, 1rem + 10vw, 3.625rem);
    }
  }

  .button-container-main-page {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    z-index: 0;
  }

  .illustrationContainer {
    display: none;
  }

  .arrowTop,
  .arrowBottom {
    display: none;
  }

  .button-container-main-page .button {
    max-width: 9.5rem;
    min-width: 9.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 0.9rem;
    }
  }
}

@media (min-width: 301px) and (max-width: 450px) {
  .main-page-container {
    position: relative;
    top: -1.5rem;
    padding-top: 1.5rem;
    height: 100%;
  }

  .overflow-hidden {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .main-page-mobile-illustration {
    width: 100%;
    display: flex;
    margin-bottom: 4.375rem;
  }

  #cyclic-arrows-container {
    position: relative;
    top: 12.8rem;
    width: 15.2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: -12.2rem;

    #arrow-right,
    #arrow-left {
      display: flex;
      width: 3rem;
    }

    #arrow-left {
      transform: rotate(180deg);
    }
  }

  .main-page-container {
    display: flex;
    flex-direction: column;
  }
  .main-page-mobile-navbar {
    display: block;

    nav {
      background-color: initial;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4.3rem;
      margin-bottom: 4rem;

      .main-page-mobile-logo-container {
        width: 5.81rem;
        margin-left: 1.25rem;

        img {
          width: 5.81rem;
          cursor: pointer;
        }
      }

      .main-page-mobile-hamburger-menu {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 1.25rem;
        cursor: pointer;
      }
    }
  }

  .logoContainerMainPage {
    display: none;
  }

  .outerContainerMainPage {
    width: 100%;
    margin: 0;
  }

  .headingContainerMainPage {
    width: 90%;
    margin: auto;

    .headingMainPage {
      font-size: clamp(2rem, 0.5rem + 10vw, 3.125rem);
      font-weight: 700;
      line-height: clamp(2rem, 0.5rem + 10vw, 3.125rem);
    }
  }

  .button-container-main-page {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    z-index: 0;
  }

  .illustrationContainer {
    display: none;
  }

  .arrowTop,
  .arrowBottom {
    display: none;
  }

  .button-container-main-page .button {
    max-width: 11rem;
    min-width: 11rem;
  }

  #cyclic-arrows-container {
    width: 17.2rem;
  }
} ;
