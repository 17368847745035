@import "../../../styles/colorbox.scss";

nav#mobile-navbar {
  display: none;
}

@media (max-width: 450px) {
  nav#mobile-navbar {
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.3rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid $dark-grey3;
    position: sticky;
    top: 0;
    z-index: 10;

    .main-page-mobile-logo-container {
      width: 5.81rem;
      margin-left: 1.25rem;

      img {
        width: 5.81rem;
      }
    }

    .main-page-mobile-hamburger-menu {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 1.25rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
