@import "../../styles/colorbox.scss";

.main-page-header {
  display: flex;
  height: 75px;
  border: 1px solid $dark-grey3;
  padding: 0.8em;
  width: 100%;
  margin-left: 0px;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: fixed;
  background-color: white;
  z-index: 2;
}
.main-navbar-link {
  display: block;
  border: 0px;
  padding: 0;
  color: $indigo-dye;
  box-shadow: none;
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 1.7px;
  font-weight: 600;
  padding: 0.8rem 1.5rem;
}

.main-navbar-item {
  font-size: 0.9rem;
  color: $indigo-dye;
  margin: 0rem 1rem;
  font-family: "Poppins";
  border-radius: 50px;
  vertical-align: middle;
}

.main-navbar-link:hover {
  background-color: $red-pill-button;
  color: white !important;
  border-radius: 50px;
  vertical-align: middle;
  padding: 0.8rem 1.5rem;
}

.main-active-navbar-item {
  background-color: $light-grey-white;
  border-radius: 50px;
  vertical-align: middle;
}

.logo-container-main-page {
  margin-left: 3rem;
  min-width: 150px;
  margin-top: 0.4rem;
}

.no-wrap {
  white-space: nowrap;
}

.hamburgerMenuContainerMainPage {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 28px;
  align-items: flex-end;
}

.hamburgerMenuOpenIcon {
  width: 26.5px;
  margin-top: 1rem;
  margin-right: 1.2rem;
  cursor: pointer;
}

.hamburgerMenuCloseIcon {
  width: 45px;
  cursor: pointer;
}
