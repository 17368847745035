@import "variables";

.error-dialog-custom {
  min-width:520px;
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-body {
    padding: 2em;
  }
  .ant-modal-confirm-title {
    font-size: 1.5em;
    text-align: center;
  }
  .ant-modal-confirm-btns {
    display: flex;
    justify-content: center;
    margin: 2em 0 0 0;
  }
  .anticon-close-circle {
    display: none;
  }
  .btn-danger-custom {
    background: $btn-danger-color;
  }
  .btn-danger-custom:hover {
    background: $btn-danger-hover-color;
  }
}