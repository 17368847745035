.processHeading {
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  color: #1b3442;
  text-align: left;
  line-height: 42px;
  letter-spacing: 0.02em;
}

.processHeading2 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.02em;
  color: $bg-blue;
  font-family: "Archivo";
}

.processHeading3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
}

.processHeading4 {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: $indigo-dye;
  font-family: "Inter";
}

.glanceHeading {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #4945459b;
}

@media (max-width: 450px) {
  .processHeading2 {
    padding: 0;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}
