@import "../../styles//colorbox.scss";

.ellipse {
  height: 1.5rem;
  margin-bottom: 0.25rem;
  margin-right: 1rem;
}

.link-with-ellipse {
  color: $indigo-dye;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;

  &:hover {
    color: $red-basic;
  }
}
