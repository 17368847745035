@import "../../../styles/colorbox.scss";

.desktop-hidden {
  display: none;
}

.mobile-grid {
  display: unset;
}

@media (max-width: 450px) {
  .desktop-hidden {
    display: flex;
  }

  .whitespace,
  .mobile-hidden {
    display: none !important;
  }

  .row {
    margin: 0;
    padding: 0;

    .col-4 {
      &.mobile-w-90\% {
        width: 90%;
        padding: 0;
        margin: 0 auto;

        .lead-content {
          padding: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  .col-4 {
    &.mobile-w-90\% {
      width: 90%;
      padding: 0;
      margin: 0 auto;

      .lead-content {
        padding: 0;
        margin-bottom: 0;
      }
    }
  }

  #what-does-this-do-button-container {
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;

    img {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .main-content-align {
    &.mt-88 {
      margin-top: 0;
    }

    &.mb-64 {
      margin-bottom: 0;
    }
  }

  .lead-content {
    .col-4 {
      font-size: 0.9rem;
    }
    b {
      font-size: 1rem;
    }
  }

  h3.col-6 {
    &.processHeading2 {
      width: 90%;
      margin: 0 auto;
      text-align: left;
    }
  }

  .principle-container {
    .lead-content {
      margin: 0;
      width: 100%;
      &.ml-24 {
        margin: 0;
      }
    }
  }

  div.mobile-flex {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;

    h5.col-8 {
      font-size: 1.25rem;
      margin: 0;
    }

    img:not(.badgeIcon) {
      width: 2.25rem;
      height: 2.25rem;
      margin-right: 1rem;
    }

    img.avatar {
      width: 1rem;
      height: 1rem;
      margin-left: 12.5px;
    }
  }

  #usecase-heading {
    margin-bottom: 3rem;
    margin-top: 1rem;
  }

  div.ml-44 {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 5rem;
    padding: 0;

    > .row > .col-6 {
      &.mobile-w-full {
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }

    &.mobile-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 1.75rem;
    }
  }

  .avatar-container {
    &:first-child {
      margin-top: 3rem;
    }
  }

  .desktop-hidden {
    &.guidanceReqInnerBox {
      display: block;
      font-size: 0.9rem;

      &.mt-4r {
        margin-top: 4rem;
      }
    }
  }

  p#what-does-this-do-heading {
    color: $indigo-dye;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0;
  }

  .processRunning {
    strong {
      font-size: 1.125rem;
    }
  }
}
