.p-0 {
  padding: 0;
}

.p-4 {
  padding: 4px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-24 {
  padding-left: 24px;
}
.pl-32 {
  padding-left: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pl-164 {
  padding-left: 100px;
}

.p-8 {
  padding: 8px;
}

.p-16 {
  padding: 16px;
}

.p-20 {
  padding: 20px;
}

.p-24 {
  padding: 24px;
}

.ph-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.ph-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.ph-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.ph-100 {
  padding-left: 100px;
  padding-right: 100px;
}
.ph-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.pv-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pv-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.pv-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.pt-24 {
  padding-top: 24px;
}
.pt-72 {
  padding-top: 72px;
}

.pr-8 {
  padding-right: 8px;
}

.pr-16 {
  padding-right: 16px;
}

.pr-24 {
  padding-right: 24px;
}

.pr-36 {
  padding-right: 36px;
}
.pr-48 {
  padding-right: 48px;
}
.pr-64 {
  padding-right: 64px;
}
