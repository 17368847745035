.comment-table-section,
.email-table-section {
  margin: 0.5em 0;
  max-width: inherit;
  overflow-x: auto;
  table,
  th,
  td {
    border: 1px solid;
    padding: 0.4em;
  }
  table {
    margin: 0 0 0.5em;
    border-collapse: collapse;
    width: 100%;
  }
}

.update-details-dialog .ant-modal {
  width: 600px !important;
}

.update-details-dialog .ant-modal-title {
  font-weight: bold;
}

.details-content-field {
  margin-left: 1.5rem;
  overflow: hidden;
  word-break: break-word;
}
