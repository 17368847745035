@import "../../../styles/colorbox.scss";
.discovery-story {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: $impure-black;
  width: 40rem;
}
.guideline-position {
  display: flex;
  width: 100%;
}

.opacity {
  opacity: 0.4;
}

.desktop-hidden {
  display: none;
}

#discovery-checklist,
#project-workplan {
  .row {
    margin-left: 0.1rem;
    margin-bottom: 3.5rem;
    flex-wrap: nowrap;
    overflow-x: scroll;

    // Scrollbar dimensions for Firefox
    scrollbar-width: thin;

    // Scrollbar dimensions for other browsers
    &::-webkit-scrollbar {
      height: 6px;
    }

    .col-4 {
      padding-left: 0;
      width: max-content;
      margin-bottom: 1rem;

      &:last-of-type {
        .discoveryChecklist,
        .discoveryChecklistActive {
          margin-right: 0;
        }
      }

      & > * {
        padding-left: 0;
        margin-right: 5rem;
      }

      .discoveryChecklist,
      .discoveryChecklistActive {
        justify-content: flex-start;
        padding-right: 0;
      }
    }
  }

  .col-12 {
    &.horizontal-rule {
      padding-left: 0;
      position: relative;
      top: -4.95rem;

      hr {
        width: 100%;
      }
    }
  }
  h1.processHeading2 {
    margin-bottom: 5rem;
  }
}

#project-workplan {
  .row {
    .col-12 {
      hr {
        left: -76rem;
        width: 102%;
      }
    }
  }
}

@media (max-width: 450px) {
  p,
  li {
    font-size: 0.9rem;
  }
  .whitespace,
  .mobile-hidden {
    display: none;
  }

  .desktop-hidden {
    display: block;
  }

  .row {
    padding: 0;
    margin: 0;
  }

  .mobile-flex {
    display: flex;
    align-items: center;
  }

  #intro-row > *,
  #principles-row > * {
    padding: 0;
    margin: 0;
  }

  #intro-row {
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 90%;
    margin: 0 auto;

    #intro-container {
      width: 100%;
      margin: 0 auto;

      .main-heading-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 0;
        margin-bottom: 2rem;

        h1 {
          margin: 0;
          padding: 0;
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
        img {
          margin-right: 1rem;
        }
      }

      .lead-content {
        width: 100%;
        margin: 0;
        padding: 0;
        p {
          font-size: 0.9rem;
          line-height: 1.5rem;
          text-align: left;
        }
      }
    }
  }

  #discovery-content-container {
    padding: 0;
    margin: 0;
    margin-top: 2rem;
    #discovery-content > * {
      padding: 0;
      margin: 0;
    }

    #discovery-content {
      width: 90%;
      padding: 0;
      margin: 0 auto;

      p {
        font-size: 0.9rem;
        line-height: 1.5rem;
        text-align: left;
      }

      .mobile-flex {
        margin-bottom: 2rem;
        h1.processHeading2 {
          padding: 0;
          margin: 0;
        }
        img {
          margin-right: 1rem;
        }
      }
    }
  }

  #principles-row {
    .col-6 {
      width: 90%;
      padding: 0;
      margin: 0 auto;

      .col-12 {
        .col-10 {
          padding: 0;
          margin: 0;
          width: 100%;

          img.desktop-hidden {
            margin-right: 1rem;
            width: 2.25rem;
            height: 2.25rem;
          }

          .mobile-flex {
            img.badgeIcon {
              margin-left: 1rem;
            }
          }

          h1.processHeading4 {
            margin: 0;
          }

          ul.processRunning {
            margin-top: 1.25rem;
          }
        }
      }
    }
  }

  .main-content-align {
    width: 90%;
    padding: 0;
    margin: 0 auto;

    .discovery-story {
      width: 100%;
      font-size: 0.9rem;

      &.mt-32 {
        margin-top: 0.9rem;
      }
    }
  }

  #linkWhatHappen {
    margin: 0;
    padding: 0;
    width: 90%;
    margin: 0 auto;
    margin-top: 1.5rem;
  }

  .guidanceReqs {
    .col-3 {
      width: 50%;
      max-width: 10rem;

      .guidance {
        margin: 0;
        margin-bottom: 1rem;

        img {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .guidelines-section-wrapper {
    .processChecklist {
      .row {
        flex-wrap: nowrap;
        overflow-x: auto;

        // Scrollbar dimensions for Firefox
        scrollbar-width: none;

        // Scrollbar dimensions for other browsers
        &::-webkit-scrollbar {
          display: none;
        }

        .col-4 {
          width: max-content;
          margin-bottom: 1rem;
          padding: 0;
          .discoveryChecklist,
          .discoveryChecklistActive {
            width: max-content;
          }
        }
      }
    }
  }

  #discovery-checklist {
    .row {
      .col-4 {
        width: max-content;
        margin-bottom: 1rem;
        padding: 0;

        &:first-of-type {
          .discoveryChecklist,
          .discoveryChecklistActive {
            padding-left: 0;
          }
        }
        & > * {
          margin: 0;
        }
        .discoveryChecklist,
        .discoveryChecklistActive {
          margin: 0;
          padding: 1.5rem;
          width: max-content;
        }

        .discoveryChecklist {
          &:hover {
            font-weight: 600;
            color: $dark-grey2;
            border-bottom: 1px solid $gray-light;
          }
        }
      }

      .col-12 {
        display: none;
      }
    }

    h1.processHeading2 {
      text-align: left;
      margin-bottom: 3rem;
    }
  }
}

@media (max-width: 330px) {
  .guidanceReqs {
    .col-3 {
      width: 50%;
      max-width: 10rem;

      .guidance {
        margin: 0;
        margin-bottom: 1rem;
        flex-direction: column;

        img {
          margin-right: 0.5rem;
        }

        .guidance-key,
        .guidance-values {
          align-self: flex-start;
        }
      }
    }
  }
  #discovery-checklist {
    * {
      font-size: 99% !important;
    }
  }
}
