.estimatesContainer {
  margin: 50px 0;
  text-align: left;
}

.parbatContainer {
  margin: 50px 0;
  text-align: left;
}

.processContainer {
  margin-top: 50px;
  padding: 40px;
  background: #ffffff;
  color: #000;
  border-radius: 10px;
}
