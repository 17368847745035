@import "./main.scss";
@import "./headings.scss";
@import "./guidance.scss";
@import "./container.scss";
@import "./colorbox.scss";
@import "./margins.scss";
@import "./paddings.scss";
@import "./flex.scss";
@import "./colors.scss";
@import "modal.scss";
@import "dimensions.scss";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
