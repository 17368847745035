.hero {
  color: #fff;
  background-color: #0e0f37;
  padding: 40px 10px;
}
.heading {
  font-family: "Inter", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 0.03em;
  margin: 10px;
  display: flex;
  align-items: center;
}
.buttonContainer {
  margin: 20px 10px 30px 10px;
}
.button {
  font-family: "Inter", sans-serif;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  letter-spacing: 0.01em;
  margin-right: 20px !important;
}
.button__green {
  background-color: #24cdaf !important;
  border: 1px solid #24cdaf !important;
}
.button__blue {
  background-color: #28a0ff;
  border: 1px solid white !important;
}
.processParentContainer {
  padding: 40px;
  background: #ffffff;
  color: #000;
  border-radius: 10px;
}
.termsContainer {
  margin-top: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.innerContentUseCase {
  background: rgba(40, 160, 255, 0.06);
  color: #4d8bbc;
  border-radius: 12px;
  margin-block: 12px;
  padding: 12px;
}
.collapse {
  background-color: #28a0ff0f;
  border-radius: 20px;
  margin: 10px;
}

.rightColumnIntro {
  margin-top: 10rem;
  margin-left: 0.25rem;
}

.timelapseWhyThePlaybook {
  width: 4.5rem;
  height: 4.5rem;
}

.personWhyThePlaybook {
  width: 4rem;
  height: 4rem;
}

.personNameWhyThePlaybook {
  color: #436578;
  text-decoration: underline;
  margin-top: 23px;
}

.personNameWhyThePlaybook:hover {
  cursor: pointer;
  color: #ff0000;
}

.principleIcon {
  height: 4.5rem;
}
.principleItem {
  margin-top: 1rem;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 42px;
  letter-spacing: 0.02em;
  color: #436578;
}

.projectLifecycle {
  margin-top: 10rem;
}

@media (max-width: 450px) {
  div[data-id="projectLifecycle"],
  .projectLifecycle {
    margin: 0;
    margin-bottom: 3rem;
  }

  .principleItem {
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.125rem;
  }

  .personWhyThePlaybook {
    width: 1rem;
    height: 1rem;
  }

  .personNameWhyThePlaybook {
    font-size: 1rem;
    margin: 0;
  }

  .rightColumnIntro {
    margin-top: 0;
  }
}
