@import "./src/styles/colorbox";

.App {
  font-family: "Lato", "Verdana", sans-serif;
}
.italic {
  font-style: italic;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: lightblue;
}

.expanded-content {
  padding-top: 20px;
  background-color: rgba(49, 0, 0, 0.199);
  transition: all 1s;
}

.uppercase {
  text-transform: uppercase;
}

/*Processes CSS*/

.processIntro {
  margin: 20px 0 40px 0;
}

.processRunning {
  font-weight: normal;
  color: $bg-blue;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 27px;
  font-family: "Inter";

  ol {
    li::marker {
      font-size: 14px;
    }
  }
}

.mandatory-participants {
  font-weight: 600;
  color: $bg-blue;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 27px;
  font-family: "Inter";
}

.processRunningHeading {
  font-weight: bold;
  color: $indigo-dye;
  font-family: "Inter";
  text-align: left;
  font-size: 20px;
  letter-spacing: 0.01em;
  line-height: 27px;
  margin-top: 1rem;
}
.box-props {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  width: 189px;
  cursor: pointer;
  font-family: "Lato", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #5e656a;
  margin-bottom: 10px;
  border: 2px dashed #0ec573;
  box-sizing: border-box;
  border-radius: 20px;
}

.whereBox {
  @extend.box-props;
}

.whereBoxActive {
  @extend.box-props;
  background: rgba(226, 248, 238, 0.67);
}

.whereDos {
  text-align: left;
}

.whereDos:first-child {
  margin-bottom: 10px;
}

.badgeIcon {
  cursor: pointer;
  margin: 0 5px;
}
.badgeIcon:hover {
  opacity: 0.8;
  transition: all 0.5s;
}

.badgeBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  background: rgba(205, 36, 36, 0.06);
  border: 1px solid rgba(205, 36, 36, 0.06);
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px 10px;

  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;

  color: #1b3442;
}

.principles {
  border-radius: 20px;
  border: 1px dashed #bbbbbb;
}

.principle {
  padding: 30px 40px;
  border-bottom: 1px dashed #bbbbbb;
}
.principle > p {
  color: black;
}

.whatImg {
  margin: 0 15px;
}

.ant-collapse {
  border: 1px solid #4aafff;
  border-radius: 8px;
}

.ant-collapse-header {
  background: rgba(40, 160, 255, 0.06);
  font-weight: bold;
  font-family: "Inter";
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: $indigo-dye !important;
}

.ant-collapse-content {
  background-color: transparent;
}

.projectsListing .ant-table-thead > tr > th {
  color: $grey-color;
  font-weight: bold;
}
.ant-collapse-content-box {
  background: rgba(40, 160, 255, 0.06);
}

.guidanceTips {
  margin-top: 30px;
  padding-left: 20px;
}

.processChecklist {
  margin: 50px 0;
  text-align: left;
}

.checklist-props {
  display: flex;
  justify-content: center;
  border-radius: 12px;
  padding: 30px;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-align: center;
  cursor: pointer;
}

.checklistActive {
  @extend.checklist-props;
  font-weight: 700;
  color: #1b3442;
  background: rgba(36, 205, 104, 0.3);
}

.checklistPointers {
  margin: 20px 0;
}

.checklist {
  @extend.checklist-props;
  font-weight: 600;
  color: #b3b3b3;
  background: rgba(36, 205, 104, 0.1);
}

.guidanceReqInnerBox {
  background: rgba(40, 160, 255, 0.06);
  color: $dark-blue;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 10px;
}
.guidanceReqInnerBox > p {
  margin: 0;
}

.scrollTopBtn {
  opacity: 0.5;
}
.scrollTopBtn:hover {
  cursor: pointer;
  opacity: 1;
}

.scrollTop {
  position: fixed;
  bottom: 20px;
  right: auto;
  left: 48%;
}
.disc-checklist-props {
  display: flex;
  justify-content: center;
  padding: 30px;
  padding-bottom: 16px;
  font-size: 1.5rem !important;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-align: center;
  cursor: pointer;
}
.discoveryChecklistActive {
  @extend.disc-checklist-props;
  font-weight: 700;
  color: $blue-color;
  border-bottom: 2px solid $tintash-blue;
}

.discoveryChecklist {
  @extend.disc-checklist-props;
  font-weight: 600;
  color: $dark-grey2;

  &:hover {
    color: $blue-color;
    border-bottom: 2px solid $tintash-blue;
  }
}

.leadConversionChecklistActive,
.releaseChecklistActive {
  @extend .discoveryChecklistActive;
  width: max-content;
}

.leadConversionChecklist,
.releaseChecklist {
  @extend .discoveryChecklist;
  width: max-content;
}

#lead-conversion-checklist,
#discovery-checklist,
#release-checklist {
  padding: 0 3.75rem;
}

@media (max-width: 450px) {
  .discoveryChecklist,
  .leadConversionChecklist,
  .releaseChecklist {
    border-bottom: 1px solid $gray-light;
  }

  #lead-conversion-checklist,
  #discovery-checklist,
  #release-checklist {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .discoveryChecklist,
  .discoveryChecklistActive,
  .leadConversionChecklist,
  .leadConversionChecklistActive,
  .releaseChecklist,
  .releaseChecklistActive {
    font-size: 1.125rem !important;
  }
}
