.heading {
  font-size: 38px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 24px 32px;
}

.create_button,
.save_button {
  background: #61cab0;
  mix-blend-mode: normal;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #61cab0;
  color: white;
  padding: 5px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-transform: capitalize;
}
.create_button:hover,
.user_button:hover {
  background-color: white;
  color: #61cab0;
  border-color: #61cab0;
}
.save_button {
  border: none;
  background: none;
  display: block;
}
.user_button {
  background: #61cab0;
  mix-blend-mode: normal;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  margin-right: 20px;
  border-radius: 5px;
  border: none;
  border: 1px solid;
  border-color: #61cab0;
  color: white;
  padding: 5px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-transform: capitalize;
}
.pm_button {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 25px;
  padding: 5px 60px;
  background: #f9f9f9;
  border: 1px solid #0d1035;
  box-sizing: border-box;
  border-radius: 3px;
  text-transform: capitalize;
  text-decoration: none;
}
.project_status {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 25px;
  padding: 5px 35px;
  background: #f9f9f9;
  border: 1px solid #0d1035;
  box-sizing: border-box;
  border-radius: 3px;
  text-transform: capitalize;
  text-decoration: none;
}

.client {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.projects {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project_heading {
  font-family: Arial, Helvetica, sans-serif;
  padding: 24px 32px 24px 62px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
}

.projectsAndClientsDashboard {
  flex-direction: row;
  display: flex;
  margin-top: 4rem;
  justify-content: space-between;
}

.projects_listing_container {
  padding: 48px;
  padding-top: 38px;
}
