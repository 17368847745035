@import "./src/styles/colorbox";

.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 15.2rem;
  min-width: 15.2rem;
  background: white;
  padding-bottom: 5rem;
  flex-basis: 25%;
  box-shadow: 4px 1px 23px -16px rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1;
  height: 100%;
  top: 4.1rem;
  overflow: scroll;
}
