.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.content-center {
  justify-content: center;
}

.flex-center-self {
  align-self: center;
}

.flex-end-self {
  align-self: flex-end;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-space-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.fl-1 {
  flex: 1;
}
